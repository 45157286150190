import React from 'react';
import cn from 'classnames';
import styles from './htlogo.module.scss';

type HTLogoProps = {
  className?: string;
  defaultCursor?: boolean;
};

/**
 * PartnerLogo
 *
 * Only to be used in headers.
 */
const HTLogo = ({className = '', defaultCursor = false}: HTLogoProps) => {
  const logoStyles = cn(
    styles.htLogo,
    {
      [styles.defaultCursor]: defaultCursor,
      topNavHelloTechLogo: true,
    },
    className,
  );

  return <img src="/_nextassets_/images/ht_door_horizontal_logo.svg" alt="HelloTech Logo" className={logoStyles}/>;
};

export default HTLogo;
